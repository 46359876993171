import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timer"]
  static values = {
    expiresAt: String
  }

  connect() {
    this.startCountdown()
  }

  disconnect() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer)
    }
  }

  startCountdown() {
    this.updateCountdown()
    this.countdownTimer = setInterval(() => {
      this.updateCountdown()
    }, 1000)
  }

  updateCountdown() {
    const now = new Date().getTime()
    const expiresAt = new Date(this.expiresAtValue).getTime()
    const reminingTime = expiresAt - now

    if (reminingTime <= 0) {
      clearInterval(this.countdownTimer)
      this.refreshQrCode()
      return
    }

    const minutes = Math.floor(reminingTime / (1000 * 60))
    const seconds = Math.floor((reminingTime % (1000 * 60)) / 1000)
    this.timerTarget.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  refreshQrCode() {
    document.querySelector('form[action*="refresh"]').requestSubmit()
  }
}