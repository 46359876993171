import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="close-alerts"
export default class extends Controller {
  static targets = ["alert"];

  connect() {
    this.autoClose();
  }

  autoClose() {
    setTimeout(this.fadeOut.bind(this), 0);
  }

  close() {
    this.alertTarget.remove();
  }

  fadeOut() {
    setTimeout(() => {
      if (this.hasAlertTarget) {
        this.alertTarget.style.opacity = 0;
      }

      setTimeout(() => {
        if (this.hasAlertTarget) {
          this.alertTarget.remove();
        }
      }, 500);
    }, 5000); // 5秒後に実行
  }
}
