import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]
  static values = {
    carouselId: String
  }

  connect() {
  }

  open() {
    this.modalTarget.classList.remove('hidden')

    const carouselController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget.querySelector('[data-controller="carousel"]'),
      'carousel'
    )
    if (carouselController) {
      carouselController.reinitialize()
    }
  }

  close() {
    this.modalTarget.classList.add('hidden')
  }

  handleBackdropClick(event) {
    if (event.target === this.modalTarget) {
      this.close()
    }
  }
}