import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // DOMが完全に読み込まれてから初期化
    setTimeout(() => {
      this.initializeSlick()
    }, 100)
  }

  initializeSlick() {
    $(this.element).slick({
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 5000,
      cssEase: 'linear',
      pauseOnHover: false,
      variableWidth: true,
      centerMode: true,
      swipe: true,
      draggable: true,
    })
  }

  disconnect() {
    this.destroySlick()
  }

  destroySlick() {
    if ($(this.element).hasClass('slick-initialized')) {
      $(this.element).slick('unslick')
    }
  }
}