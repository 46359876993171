// Import and register all your controllers from the importmap via controllers/**/*_controller
import { application } from "./application"
import CloseAlertsController from "./close_alerts_controller"
import QrCountdownController from "./qr_countdown_controller"
import CarouselController from "./carousel_controller"
import AutoLoopCarouselController from "./auto_loop_carousel_controller"
import ModalController from "./modal_controller"
import CallController from "./call_controller"
application.register("close-alerts", CloseAlertsController)
application.register("qr-countdown", QrCountdownController)
application.register("carousel", CarouselController)
application.register("auto-loop-carousel", AutoLoopCarouselController)
application.register("modal", ModalController)
application.register("call", CallController)