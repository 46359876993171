import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initializeSlick()
  }

  reinitialize() {
    // 既存のSlickを破棄して再初期化
    this.destroySlick()
    setTimeout(() => {
      this.initializeSlick()
    }, 100)
  }

  initializeSlick() {
    $(this.element).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: '60px',
      speed: 500,
      variableWidth: false,
      focusOnSelect: true,
      dots: true,
      arrows: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    })
  }

  previous() {
    $(this.element).slick('slickPrev')
  }

  next() {
    $(this.element).slick('slickNext')
  }

  disconnect() {
    this.destroySlick()
  }

  destroySlick() {
    if ($(this.element).slick) {
      $(this.element).slick('unslick')
    }
  }
}