import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["status", "callButton", "modal", "initiatingContent", "connectedContent"]
  static values = {
    initiateUrl: String,
    equipmentId: String
  }

  connect() {
    this.device = null
    this.isInitialized = false
    this.currentConnection = null
    this.initializeChannel()
  }

  initializeChannel() {
    if (!this.hasEquipmentIdValue) return

    this.channel = consumer.subscriptions.create(
      {
        channel: "CallChannel",
        equipment_id: this.equipmentIdValue
      },
      {
        received: (data) => this.handleCallStatus(data.status)
      }
    )
  }

  handleCallStatus(status) {
    if (['initiated', 'ringing'].includes(status)) {
      this.showCallModal('initiating')
    } else if (['in-progress', 'connected'].includes(status)) {
      this.showCallModal('connected')
    } else {
      this.hideCallModal()
    }
  }

  showCallModal(status) {
    if (!this.hasModalTarget) return

    this.modalTarget.classList.remove('hidden')
    const isInitiating = status === 'initiating'
    this.initiatingContentTarget.classList.toggle('hidden', !isInitiating)
    this.connectedContentTarget.classList.toggle('hidden', isInitiating)
  }

  hideCallModal() {
    if (!this.hasModalTarget) return

    this.modalTarget.classList.add('hidden')
    this.initiatingContentTarget?.classList.add('hidden')
    this.connectedContentTarget?.classList.add('hidden')
  }

  async setupDevice() {
    const token = await this.fetchToken()
    if (!token) return null

    this.device = new Twilio.Device(token, {
      debug: false,
      enableRingingState: true,
      allowIncomingWhileBusy: true,
      closeProtection: true,
      audio: {
        incoming: true,
        outgoing: true
      },
      audioConstraints: {
        autoGainControl: true,
        echoCancellation: true,
        noiseSuppression: true,
        channelCount: 1
      }
    })

    this.setupDeviceEventListeners()
    return this.device
  }

  async fetchToken() {
    try {
      const response = await fetch('/twilio/token', {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        },
        credentials: 'same-origin'
      })

      if (!response.ok) return null
      const data = await response.json()
      return data.token
    } catch {
      return null
    }
  }

  setupDeviceEventListeners() {
    this.device.on('error', error => {
      if (!error.message.includes('Devices not found')) {
        this.currentConnection = null
        this.hideCallModal()
      }
    })

    this.device.on('connect', connection => {
      this.currentConnection = connection
      this.showCallModal('connected')
    })

    this.device.on('disconnect', () => {
      this.currentConnection = null
      this.hideCallModal()
    })

    this.device.on('incoming', connection => {
      connection.accept()
    })
  }

  // create_twilio_callアクションへ発信リクエスト
  async initiateCall() {
    try {
      // デバイスの初期化
      const device = await this.initializeDeviceIfNeeded()
      if (!device) throw new Error("デバイスの初期化に失敗しました")

      this.showCallModal('initiating')

      // オーディオデバイスのチェック
      const hasAudio = await this.checkAudioDevices()
      if (!hasAudio) {
        this.hideCallModal()
        throw new Error("マイクが利用できません")
      }

      // 発信リクエスト
      const response = await fetch(this.initiateUrlValue, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        },
        credentials: 'same-origin'
      })

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || '発信に失敗しました')
      }
    } catch (error) {
      this.hideCallModal()
      throw error
    }
  }

  async initializeDeviceIfNeeded() {
    if (!this.isInitialized) {
      await this.setupDevice()
      await this.setupAudioDevices()
      this.isInitialized = true
    }
    return this.device
  }

  async checkAudioDevices() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const devices = await navigator.mediaDevices.enumerateDevices()
    const hasAudioDevices = devices.some(device => device.kind === 'audioinput')
    stream.getTracks().forEach(track => track.stop())
    return hasAudioDevices
  }

  async setupAudioDevices() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        },
        video: false
      })

      const devices = await navigator.mediaDevices.enumerateDevices()
      const hasAudioDevices = devices.some(device => device.kind === 'audioinput')
      stream.getTracks().forEach(track => track.stop())

      if (this.device?.audio) {
        try {
          await this.device.audio.speakerDevices.test()
        } catch {
          // スピーカーテストの失敗は無視
        }
      }

      return hasAudioDevices
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        throw new Error('マイクへのアクセスが許可されていません')
      }
      if (error.name === 'NotFoundError') {
        throw new Error('マイクが接続されていないか、見つかりません')
      }
      return true
    }
  }

  disconnect() {
    this.channel?.unsubscribe()
    this.device?.destroy()
    this.device = null
    this.isInitialized = false
  }
}